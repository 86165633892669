<template>
  <div class="mx-md-n2 mx-n0">

    <div class="row mt-3 no-gutters mx-n1">

      <div id="scrollContainer" class="col-xl-9 col-lg-9 col-md-9 col-12 px-2">

        <b-alert v-if="$route.query.appointment_id && $route.query.doctor_id" show variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b">
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            Perjanjian <strong>{{ doctors[doctors.findIndex(x => x.id == $route.query.doctor_id)].label }}</strong>
          </div>
          <b-button squared variant="success"
            @click="$router.push(`/appointment/detail/${$route.query.appointment_id}`)">Detail</b-button>
        </b-alert>

        <Card>
          <template v-slot:body>

            <div class="row align-items-center">
              <!-- Input Modal Trigger -->
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 px-1 px-md-3 my-n2">
                <b-form-group id="input-group-trigger-modal-patient" label="Pasien:"
                  label-for="input-trigger-modal-patient">
                  <b-form-input id="input-trigger-modal-patient" v-model="display.patient_name" placeholder="Pilih Pasien"
                    readonly :disabled="form.payment_status_id == 2 || form.has_usage != 0"
                    @click="$bvModal.show('modal-patient')">
                  </b-form-input>
                  <small class="text-danger">{{ error.patient_id }}</small>
                </b-form-group>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 px-1 px-md-3 my-n2">
                <!-- Input Doctor -->
                <b-form-group v-if="currentUser.user_type_id != 1" id="input-group-doctor" label="Dokter:"
                  label-for="input-doctor-treeselect">
                  <treeselect id="input-doctor-treeselect" v-model="form.doctor_id"
                    :disabled="(form.payment_status_id == 2 || form.has_usage != 0) && form.doctor_id != ''"
                    :multiple="false" :options="doctors" />
                  <small class="text-danger">{{ error.doctor_id }}</small>
                </b-form-group>
              </div>
            </div>

            <b-collapse id="collapseSubPatient" v-model="showCollapseSubPatient">
              <div class="row align-items-center">
                <!-- Input Modal Trigger -->
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 px-1 px-md-3 my-n2">
                  <b-form-group id="input-group-trigger-modal-subpatient" label="Sub Pasien:"
                    label-for="input-trigger-modal-subpatient">
                    <b-input-group>
                      <b-form-input id="input-trigger-modal-subpatient" v-model="form.sub_patient_name"
                        placeholder="Pilih Sub Pasien" readonly
                        :disabled="form.payment_status_id == 2 || form.has_usage != 0"
                        @click="$bvModal.show('modal-subpatient')"
                        :style="form.sub_patient_id != null ? `border-right: none` : ``">
                      </b-form-input>
                      <template #append v-if="form.sub_patient_id != null">
                        <b-input-group-text squared :class="btnClearSubPatientClass" style="border-left: none"
                          @click="btnClearSubPatientOnClick" @mouseover="btnClearSubPatientClass = 'bg-light-danger'"
                          @mouseout="btnClearSubPatientClass = 'bg-white'">
                          <i class="fas fa-sm fa-times text-danger"></i>
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                    <small class="text-danger">{{ error.sub_patient_id }}</small>
                  </b-form-group>
                </div>
              </div>
            </b-collapse>

            <div class="row align-items-center">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 px-1 px-md-3 my-n2">
                <b-form-group id="input-group-trigger-modal-payment-category" label="Layanan:"
                  label-for="input-trigger-modal-payment-category">
                  <b-input-group>
                    <b-form-input id="input-trigger-modal-payment-category"
                      v-model="mutated_display_payment_categories_name" placeholder="Pilih Layanan" readonly
                      :disabled="form.payment_status_id == 2 || form.has_usage != 0"
                      @click="$bvModal.show('modal-payment-category')" style="border-right: none">
                    </b-form-input>
                    <template #append>
                      <b-input-group-text squared :class="btnClearSelectedServiceClass" style="border-left: none"
                        @click="btnClearSelectedServiceOnClick"
                        @mouseover="btnClearSelectedServiceClass = 'bg-light-danger'"
                        @mouseout="btnClearSelectedServiceClass = 'bg-white'">
                        <i class="fas fa-sm fa-times text-danger"></i>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ error.payment_categories_id }}</small>
                </b-form-group>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 px-1 px-md-3 my-n2">
                <b-form-group id="input-group-trigger-modal-medicine" label="Obat:"
                  label-for="input-trigger-modal-medicine">
                  <b-input-group>
                    <b-form-input id="input-trigger-modal-medicine" v-model="mutated_display_medicines_name"
                      placeholder="Pilih Obat" readonly :disabled="form.payment_status_id == 2 || form.has_usage != 0"
                      @click="$bvModal.show('modal-medicine')" style="border-right: none"></b-form-input>
                    <template #append>
                      <b-input-group-text squared :disabled="form.payment_status_id == 2 || form.has_usage != 0"
                        :class="btnClearSelectedMedicineClass" style="border-left: none"
                        @click="btnClearSelectedMedicineOnClick"
                        @mouseover="btnClearSelectedMedicineClass = 'bg-light-danger'"
                        @mouseout="btnClearSelectedMedicineClass = 'bg-white'">
                        <i class="fas fa-sm fa-times text-danger"></i>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ error.medicines_id }}</small>
                </b-form-group>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 px-1 px-md-3 my-n2">
                <!-- Input Lab Procedures -->
                <b-form-group id="input-group-lab-procedures" label="Laboratorium:" label-for="input-lab-procedures">
                  <b-input-group>
                    <b-form-input id="input-trigger-modal-lab-procedure"
                      v-model="mutated_display_lab_procedures_name" placeholder="Pilih Laboratorium" readonly
                      :disabled="form.payment_status_id == 2" @click="$bvModal.show('modal-lab-procedure')"
                      style="border-right: none"></b-form-input>
                    <template #append>
                      <b-input-group-text squared :class="btnClearSelectedLabProcedureClass" style="border-left: none"
                        @click="btnClearSelectedLabProcedureOnClick"
                        @mouseover="btnClearSelectedLabProcedureClass = 'bg-light-danger'"
                        @mouseout="btnClearSelectedLabProcedureClass = 'bg-white'">
                        <i class="fas fa-sm fa-times text-danger"></i>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ error.lab_procedures_id }}</small>
                </b-form-group>
              </div>
            </div>

          </template>
        </Card>

        <!-- table layanan -->
        <div class="row no-gutters mx-n1">
          <div class="col-md-6 col-12 px-2">
            <Card style="min-height: 308.65px; max-height: 400px; overflow-y: auto" title="Layanan">
              <template v-slot:body>
                <div class="d-flex justify-content-center mt-n5 mx-n5">
                  <b-table hover responsive :items="form.payment_categories_id" :fields="serviceFields">

                    <template #table-colgroup="scope">
                      <col v-for="field in scope.serviceFields" :key="field.key" />
                    </template>

                    <template v-slot:cell(index)="data">
                      {{ data.index }}
                    </template>

                    <template #cell(label)="data">
                      <div><b>{{ data.item.name }}</b></div>
                      <div>Rp {{ parseInt(data.item.const_price).toLocaleString('id-ID') }}</div>
                    </template>

                    <template #cell(price)="data">
                      <div class="text-right">
                        <div class="text-hover-success" @click="!(form.payment_status_id == 2 || form.has_usage != 0) ?
                          btnQuotePriceOnClick('payment-category', data.item, data.index) : false">
                          <b>{{ parseInt(data.item.price - data.item.promo_amount).toLocaleString("id-ID") }}</b>
                        </div>
                        <small class="d-block text-muted" v-if="data.item.promo_amount > 0">
                          <b>- {{ parseInt(data.item.promo_amount).toLocaleString('id-ID') }}</b>
                        </small>
                        <small class="d-block text-muted" v-if="data.item.discount_amount > 0">
                          <b>- {{ parseInt(data.item.discount_amount).toLocaleString('id-ID') }}</b>
                        </small>
                      </div>
                    </template>

                    <template #cell(discount)="data">
                      <b-button size="sm" class="mr-1 p-2 bg-light-success text-success font-weight-bold"
                        v-b-tooltip.hover title="Diskon" @click="!(form.payment_status_id == 2 || form.has_usage != 0) ? btnItemDiscountOnClick(
                          data.index,
                          data.item.id,
                          data.item.label,
                          data.item.const_price,
                          data.item.discount_percentage,
                          data.item.discount_amount,
                          data.item.discount_type,
                          'payment-category',
                          data.item.quantity
                        ) : false">
                        {{
                          data.item.discount_type == 0 ?
                          `${parseInt(data.item.discount_amount).toLocaleString('id-ID')}` :
                          `${data.item.discount_percentage != 0 ? `${data.item.discount_percentage}%` : 0}`
                        }}
                      </b-button>
                    </template>

                    <template #cell(actions)="data">
                      <div class="btn-group btn-group-sm">
                        <b-button squared class="btn btn-sm btn-info square"
                          @click="btnMinServiceQtyOnClick(data.item, data.index)"
                          :disabled="form.payment_status_id == 2 || form.has_usage != 0">
                          <i class="fas fa-sm fa-fw fa-minus"></i>
                        </b-button>
                        <span>
                          <button
                            class="btn btn-sm btn-block bg-white btn-quantity"
                            :disabled="form.payment_status_id == 2"
                            @click="btnCustomItemQtyOnClick(data.item, 'payment-category')">
                            {{ data.item.quantity }}
                          </button>
                        </span>
                        <b-button squared class="btn btn-sm btn-info"
                          @click="btnAddServiceQtyOnClick(data.item, data.index)"
                          :disabled="form.payment_status_id == 2 || form.has_usage != 0">
                          <i class="fas fa-sm fa-fw fa-plus"></i>
                        </b-button>
                      </div>
                    </template>

                    <template #cell(is_deposit)="data">
                      <div class="d-flex justify-content-center">
                        <b-form-checkbox switch size="lg"
                          :class="form.payment_status_id == 2 || form.has_usage != 0 || data.item.custom_input ? 'disabled' : ''"
                          :disabled="form.payment_status_id == 2 || form.has_usage != 0 || data.item.custom_input"
                          :checked="data.item.is_deposit == 1 ? true : false"
                          @change="checkboxDepositOnChange(data.item.is_deposit, data.index)">
                        </b-form-checkbox>
                      </div>
                    </template>

                  </b-table>
                </div>
              </template>
            </Card>
          </div>

          <!-- table obat -->
          <div class="col-md-6 col-12 px-2">
            <Card style="min-height: 308.65px; max-height: 400px; overflow-y: auto" title="Obat">
              <template v-slot:body>
                <div class="d-flex justify-content-center mt-n5 mx-n5">
                  <b-table hover responsive :items="form.medicines_id" :fields="medicineFields">

                    <template #table-colgroup="scope">
                      <col v-for="field in scope.medicineFields" :key="field.key" />
                    </template>

                    <template #cell(index)="data">
                      {{ data.index }}
                    </template>

                    <template #cell(label)="data">
                      <div><b>{{ data.item.name }}</b></div>
                      <div>Rp {{ parseInt(data.item.const_price).toLocaleString('id-ID') }}</div>
                    </template>

                    <!-- <template #cell(price)="data">
                      <div class="text-right">{{ parseInt(data.item.price).toLocaleString('id-ID') }}</div>
                    </template> -->

                    <template #cell(price)="data">
                      <div class="text-right">
                        <div>
                          <b>{{ parseInt(data.item.price - data.item.promo_amount).toLocaleString("id-ID") }}</b>
                        </div>
                        <small class="d-block text-muted" v-if="data.item.promo_amount > 0">
                          <b>- {{ parseInt(data.item.promo_amount).toLocaleString('id-ID') }}</b>
                        </small>
                        <small class="d-block text-muted" v-if="data.item.discount_amount > 0">
                          <b>- {{ parseInt(data.item.discount_amount).toLocaleString('id-ID') }}</b>
                        </small>
                      </div>
                    </template>

                    <template #cell(discount)="data">
                      <b-button
                        size="sm"
                        class="mr-1 bg-light-success text-success font-weight-bold"
                        v-b-tooltip.hover
                        :disabled="form.payment_status_id == 2 || form.has_usage != 0"
                        title="Diskon"
                        @click="btnItemDiscountOnClick(
                          data.index,
                          data.item.id,
                          data.item.label,
                          data.item.const_price,
                          data.item.discount_percentage,
                          data.item.discount_amount,
                          data.item.discount_type,
                          'medicine',
                          data.item.quantity
                        )">
                        {{
                          data.item.discount_type == 0 ?
                          `${parseInt(data.item.discount_amount).toLocaleString('id-ID')}` :
                          `${data.item.discount_percentage != 0 ? `${data.item.discount_percentage}%` : 0}`
                        }}
                      </b-button>
                    </template>

                    <template #cell(actions)="data">
                      <div class="btn-group btn-group-sm" style="max-width: 100px">
                        <b-button
                          squared
                          class="btn btn-sm btn-info square"
                          :disabled="form.payment_status_id == 2 || form.has_usage != 0"
                          @click="btnMinMedicineQtyOnClick(data.item.id, data.index)">
                          <i class="fas fa-sm fa-fw fa-minus"></i>
                        </b-button>
                        <span>
                          <button
                            class="btn btn-sm btn-block bg-white btn-quantity"
                            :disabled="form.payment_status_id == 2"
                            @click="btnCustomItemQtyOnClick(data.item, 'medicine')">
                            {{ data.item.quantity }}
                          </button>
                        </span>
                        <b-button
                          squared
                          class="btn btn-sm btn-info"
                          :disabled="form.payment_status_id == 2 || form.has_usage != 0"
                          @click="btnAddMedicineQtyOnClick(data.index)">
                          <i class="fas fa-sm fa-fw fa-plus"></i>
                        </b-button>
                      </div>
                    </template>

                  </b-table>
                </div>
              </template>
            </Card>
          </div>

          <!-- table laboratorium -->
          <div class="col-md-12 col-12 px-2">
            <Card style="min-height: 308.65px; max-height: 400px; overflow-y:auto" title="Laboratorium">
              <template v-slot:body>
                <div class="d-flex justify-content-center mx-n5 mt-n5">
                  <b-table hover responsive :items="form.lab_procedures_id" :fields="generalFields">

                    <template #table-colgroup="scope">
                      <col v-for="field in scope.generalFields" :key="field.key" />
                    </template>

                    <template #cell(label)="data">
                      <div><b>{{ data.item.label }}</b></div>
                      <div>Rp {{ parseInt(data.item.const_price).toLocaleString('id-ID') }}</div>
                    </template>

                    <template #cell(price)="data">
                      <div class="text-right">
                        <div>
                          <b>{{ parseInt(data.item.price - data.item.promo_amount).toLocaleString("id-ID") }}</b>
                        </div>
                        <small class="d-block text-muted" v-if="data.item.promo_amount > 0">
                          <b>- {{ parseInt(data.item.promo_amount).toLocaleString('id-ID') }}</b>
                        </small>
                        <small class="d-block text-muted" v-if="data.item.discount_amount > 0">
                          <b>- {{ parseInt(data.item.discount_amount).toLocaleString('id-ID') }}</b>
                        </small>
                      </div>
                    </template>

                    <template #cell(promo_amount)="data">
                      <span class="font-weight-bold">{{ parseInt(data.item.promo_amount).toLocaleString('id-ID') }}</span>
                    </template>

                    <template #cell(discount)="data">
                      <b-button size="sm" class="p-2 bg-light-success text-success" v-b-tooltip.hover title="Diskon"
                        @click="form.payment_status_id != 2 ?
                          btnItemDiscountOnClick(
                            data.index,
                            data.item.id,
                            data.item.label,
                            data.item.const_price,
                            data.item.discount_percentage,
                            data.item.discount_amount,
                            data.item.discount_type,
                            'lab-procedure',
                            1
                          ) : false">
                        {{
                          data.item.discount_type == 0 ?
                          `${parseInt(data.item.discount_amount).toLocaleString('id-ID')}` :
                          `${data.item.discount_percentage}%`
                        }}
                      </b-button>
                    </template>

                    <template #cell(actions)="data">
                      <b-button size="sm" :class="`btn-danger ${form.payment_status_id == 2 ? 'disabled' : ''}`"
                        :disabled="form.payment_status_id == 2 || form.has_usage != 0" v-b-tooltip.hover title="Hapus"
                        @click="labProcedureOnDeselect(data.item)"><i class="fas fa-sm fa-times px-0"></i></b-button>
                    </template>

                  </b-table>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-lg-3 col-md-3 col-12 px-2">
        <Card>
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between mt-n2">
              <h3>Subtotal</h3>
              <h3 class="font-weight-bold">{{ parseInt(form.total_amount +
                form.total_promo_amount).toLocaleString('id-ID') }}</h3>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-n2">
              <div>Potongan Promo</div>
              <div class="font-weight-bold">-{{ parseInt(form.total_promo_amount).toLocaleString('id-ID') }}</div>
            </div>
          </template>
        </Card>
        <Card class="mt-n5">
          <template v-slot:body>
            <!-- Input Surcharge -->
            <b-form-group class="my-1" id="input-group-surcharge" label="Biaya Tambahan:" label-for="input-surcharge">
              <b-form-input id="input-surcharge" inputmode="numeric" :disabled="form.payment_status_id == 2"
                v-model="formatedSurcharge" placeholder="Surcharge" @keyup="inputAdditonKeyup">
              </b-form-input>
              <small class="text-danger">{{ error.surcharge }}</small>
            </b-form-group>

            <!-- Input Discount -->
            <b-form-group class="my-1" id="input-group-discount" label="Diskon:" label-for="input-discount">
              <b-form-input id="input-discount" inputmode="numeric" :disabled="form.payment_status_id == 2"
                v-model="formatedDiscount" placeholder="Diskon" @keyup="inputAdditonKeyup">
              </b-form-input>
              <small class="text-danger">{{ error.discount }}</small>
            </b-form-group>

            <!-- Input coupon -->
            <b-form-group class="my-1" id="input-group-trigger-modal-coupon" label="Kupon:"
              label-for="input-trigger-modal-coupon">
              <b-input-group>
                <b-form-input id="input-trigger-modal-coupon" v-model="couponInfo" placeholder="Pilih Kupon (opsional)"
                  :disabled="form.payment_status_id == 2"
                  :style="showCouponAlert && (form.payment_status_id != 2 && form.payment_status_id != 3) ? 'border-right: none;' : ''"
                  @click="$bvModal.show('modal-coupon')">
                </b-form-input>
                <template #append v-if="showCouponAlert && (form.payment_status_id != 2 && form.payment_status_id != 3)">
                  <b-input-group-text squared :class="btnClearCouponClass" style="border-left: none"
                    @click="couponOnDismissed" @mouseover="btnClearCouponClass = 'bg-light-danger'"
                    @mouseout="btnClearCouponClass = 'bg-white'">
                    <i class="fas fa-sm fa-times text-danger"></i>
                  </b-input-group-text>
                </template>
              </b-input-group>
            </b-form-group>

            <!-- Input Notes -->
            <b-form-group class="my-1" id="input-group-notes" label="Catatan:" label-for="input-notes">
              <b-form-input id="input-notes" v-model="form.invoice_notes" :disabled="form.payment_status_id == 2"
                placeholder="Catatan...">
              </b-form-input>
              <small class="text-danger">{{ error.notes }}</small>
            </b-form-group>
          </template>
        </Card>
        <Card class="mt-n5">
          <template v-slot:body>
            <b-alert id="coupon-alert" v-model="showCouponAlert"
              :dismissible="showCouponAlert && (form.payment_status_id != 2 && form.payment_status_id != 3)"
              @dismissed="couponOnDismissed">
              <b>Kupon Diterapkan</b>
              <div>{{ couponInfo }}</div>
            </b-alert>
            <div class="d-flex align-items-center justify-content-between mx-n3">
              <h3>Total</h3>
              <h3 class="font-weight-bold">{{ parseInt(form.final_amount).toLocaleString('id-ID') }}</h3>
            </div>
            <template v-if="form.payment_status_id == 2">
              <div v-for="(item, index) in payment_slips" :key="item.id"
                class="d-flex align-items-center justify-content-between mx-n3">
                <span style="font-size: 12.5px">Pembayaran #{{ index + 1 }}</span>
                <span style="font-size: 12.5px" class="font-weight-bold">{{
                  parseInt(item.payment_amount).toLocaleString('id-ID') }}</span>
              </div>
            </template>
            <div class="row no-gutters mx-n4 mt-4">
              <div class="col-md p-1" v-if="form.payment_status_id != 2">
                <button class="btn btn-lg btn-block btn-primary" @click="btnSaveOnClick">
                  <div class="d-flex align-items-center justify-content-center">
                    <i class="fas fa-fw fa-save"></i>
                    <span>Simpan</span>
                  </div>
                </button>
              </div>
              <div class="col-md p-1" v-if="!$route.query.appointment_id && !$route.query.doctor_id">
                <button class="btn btn-lg btn-block btn-success" @click="btnCheckoutOnClick">
                  <div class="d-flex align-items-center justify-content-center">
                    <i class="fas fa-fw fa-cash-register"></i><span>Bayar</span>
                  </div>
                </button>
              </div>
            </div>
          </template>
        </Card>
      </div>

    </div>

    <!-- Modal Item Quantity -->
    <b-modal cancel-title="Batal" id="modal-item-quantity"
      :title="`Atur Jumlah ${item.name} ${item.type == 'medicine' ? `- Sisa Stok ${item.stock}` : ''}`"
      @ok.prevent="modalItemQuantityOnSubmit(item)">
      <b-form-group id="input-group-quantity" label="Jumlah Pembelian" label-for="input-quantity">
        <b-form-input id="input-quantity" v-model="item.quantity" type="number" placeholder="Masukan jumlah pembelian"
          required>
        </b-form-input>
      </b-form-group>
    </b-modal>

    <!-- Modal Item Quote Price -->
    <b-modal cancel-title="Batal" id="modal-quote-price"
      :title="`Ubah Harga ${item.name} - Rp ${parseInt(item.default_price).toLocaleString('id-ID')}`"
      @ok="modalQuotePriceOnSubmit(item)">
      <b-alert id="quote-alert" v-model="showQuoteAlert" dismissible @dismissed="quoteOnDismissed">
        <b>Harga sudah di quote</b>
        <div>Harga asli - Rp {{ parseInt(item.default_price).toLocaleString('id-ID') }}</div>
      </b-alert>
      <b-form-group id="input-group-quote-price" label="Harga Baru (Rp):" label-for="input-quote-price">
        <b-form-input id="input-quote-price" v-model="formatedItemQuotePrice" placeholder="Harga (Rp)" required>
        </b-form-input>
      </b-form-group>
    </b-modal>

    <!-- Modal Item Discount -->
    <b-modal cancel-title="Batal" id="modal-item-discount"
      :title="`Diskon ${item.name} - Rp ${parseInt(item.default_price).toLocaleString('id-ID')}`"
      @ok="modalItemDiscountOnSubmit">
      <b-tabs content-class="mt-4" class="mt-n2">
        <b-tab :active="item.discount_type == 0 ? true : false" title="Nominal (Rp)" @click="item.discount_type = 0">
          <b-form @submit.stop.prevent="modalItemDiscountOnSubmit">
            <b-form-group id="input-group-discount-amount" label="Diskon (Rp):" label-for="input-discount-amount">
              <b-form-input id="input-discount-amount" v-model="formatedItemDiscountAmount" placeholder="Diskon (Rp)">
              </b-form-input>
              <small class="text-danger">{{ error.item_discount }}</small>
            </b-form-group>
          </b-form>
        </b-tab>
        <b-tab :active="item.discount_type == 1 ? true : false" title="Persentase (%)" @click="item.discount_type = 1">
          <b-form @submit.stop.prevent="modalItemDiscountOnSubmit">
            <b-form-group id="input-group-discount-percentage" label="Diskon (%):" label-for="input-discount-percentage">
              <b-form-input id="input-discount-percentage" v-model="item.discount_percentage" placeholder="Diskon (%)"
                type="number">
              </b-form-input>
              <small class="text-danger">{{ error.item_discount }}</small>
            </b-form-group>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-modal>

    <!-- Modal Coupon -->
    <ModalCoupon @chosenCoupon="modalCouponOnSelect" />

  </div>
</template>

<script>

// components
import ModalCoupon from "@/component/general/ModalCoupon.vue"
import Card from '@/view/content/Card.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import module from '@/core/modules/CrudModule.js'
import { getPromoAmount } from "@/core/modules/Helper.js"
import validation from '@/core/modules/ValidationModule.js'
import { getUser } from '@/core/services/jwt.service.js'
import Swal from 'sweetalert2'

export default {

  components: {
    Card,
    ModalCoupon,
    Treeselect,
  },

  props: {
    route: String,
    isEdit: Boolean,
    form: Object,
    error: Object,
    doctors: Array,
    payment_slips: Array,
    display: Object,
  },

  data() {
    return {
      // table selected services fields
      serviceFields: [
        {
          key: "label",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: 'Harga',
          sortable: true,
        },
        {
          key: "discount",
          label: 'Diskon',
        },
        {
          key: "actions",
          label: 'Jumlah',
        },
        {
          key: "is_deposit",
          label: 'Deposit',
        },
      ],
      generalFields: [
        {
          key: "label",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: 'Harga',
          thClass: 'text-right',
          sortable: true,
        },
        {
          key: "discount",
          label: 'Diskon',
        },
        {
          key: "actions",
          label: 'Aksi',
        },
      ],
      medicineFields: [
        {
          key: "label",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: 'Harga',
          sortable: true,
        },
        {
          key: "discount",
          label: 'Diskon',
          thStyle: { width: '20%' }
        },
        {
          key: "actions",
          label: 'Jumlah',
          thStyle: { width: '15%' }
        }
      ],
      // coupon
      coupon: null,
      // other
      item: {
        index: 0,
        id: '',
        name: '',
        is_quote: 0,
        default_price: '',
        const_price: '',
        quote_price: '',
        discount_amount: 0,
        discount_percentage: 0,
        discount_type: 0,
        quantity: 0,
        stock: 0,
        type: ''
      },
      btnClearSelectedMedicineClass: 'bg-white',
      btnClearSelectedServiceClass: 'bg-white',
      btnClearSelectedLabProcedureClass: 'bg-white',
      btnClearSubPatientClass: 'bg-white',
      btnClearCouponClass: 'bg-white',
      oldSelectedMedicine: null,
      currentUser: getUser(),
      isAllServiceDeposit: false,
      showCollapseSubPatient: false,
    }
  },

  computed: {

    mutated_display_medicines_name: {
      set(newValue) {
        this.display.medicines_name_array = newValue
      },
      get() {
        let str = ''
        this.display.medicines_name_array.forEach(value => {
          str += `${value.name}, `
        })
        return str
      }
    },

    mutated_display_payment_categories_name: {
      set(newValue) {
        this.display.payment_categories_name_array = newValue
      },
      get() {
        let str = ''
        this.display.payment_categories_name_array.forEach(value => {
          str += `${value.name}, `
        })
        return str
      }
    },

    mutated_display_lab_procedures_name: {
      set(newValue) {
        this.display.lab_procedures_name_array = newValue
      },
      get() {
        let str = ''
        this.display.lab_procedures_name_array.forEach(value => {
          str += `${value.name}, `
        })
        return str
      }
    },

    formatedSurcharge: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.surcharge = newValue.toLocaleString('id-ID')
        } else {
          this.form.surcharge = 0
        }
      },
      get() {
        return this.form.surcharge
      }
    },

    formatedItemQuotePrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.item.quote_price = newValue.toLocaleString('id-ID')
        } else {
          this.item.quote_price = 0
        }
      },
      get() {
        return this.item.quote_price.toLocaleString('id-ID')
      }
    },

    formatedItemDiscountAmount: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.item.discount_amount = newValue.toLocaleString('id-ID')
        } else {
          this.item.discount_amount = 0
        }
      },
      get() {
        return this.item.discount_amount.toLocaleString('id-ID')
      }
    },

    formatedDiscount: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.discount = newValue.toLocaleString('id-ID')
        } else {
          this.form.discount = 0
        }
      },
      get() {
        return this.form.discount
      }
    },

    couponInfo: {
      set(value) {
        value = value
      },
      get() {
        return this.coupon ? this.displayCoupon() : ''
      }
    },

    couponAmount: {
      set(value) {
        value = value
      },
      get() {
        return this.getCouponAmount(true)
      }
    },

    showCouponAlert: {
      set(value) {
        value = value
      },
      get() {
        return this.coupon != null
      }
    },

    showQuoteAlert: {
      set(value) {
        value = value
      },
      get() {
        return this.item && this.item.is_quote == 1
      }
    }

  },

  methods: {

    couponOnDismissed() {
      this.coupon = null
      // reset final amount
      this.form.final_amount = this.form.final_amount + this.form.coupon_amount
      // reset coupon
      this.form.coupon_id = null
      this.form.coupon_name = ''
      this.form.coupon_is_percent = 0
      this.form.coupon_percentage_amount = 0
      this.form.coupon_amount = 0
    },

    quoteOnDismissed() {
      let context = false
      if (this.item.type == 'payment-category') {
        context = this.form.payment_categories_id
      }
      if (context) {
        let found = context[this.item.id]
        if (!found) {
          Swal.fire('Pemberitahuan', 'Terjadi kesalahan', 'warning')
          return
        }
        // restore summary
        this.form.total_amount -= (parseInt(found.price) - (found.discount_amount * found.quantity) - found.promo_amount)
        this.form.final_amount -= (parseInt(found.price) - (found.discount_amount * found.quantity) - found.promo_amount)
        this.form.total_promo_amount -= parseInt(found.promo_amount * found.quantity)
        // set new value        
        found.is_quote = 0
        found.price = found.const_price
        found.discount_amount = this.getDiscountAmount(found, found.const_price)
        found.promo_amount = getPromoAmount(found.promo, found.price)
        // re calculate summary
        this.form.total_amount += parseInt((found.price - found.discount_amount) * found.quantity - found.promo_amount)
        this.form.final_amount += parseInt((found.price - found.discount_amount) * found.quantity - found.promo_amount)
        this.form.total_promo_amount += parseInt(found.promo_amount * found.quantity)
        // dismiss modal
        this.$bvModal.hide('modal-quote-price')
      }
    },

    getPromoInfo(item) {
      let result = ``
      if (item.promo) {
        if (item.promo.is_percent == 1)
          result = `${parseInt(item.promo.amount)}%, ${parseInt(item.price * item.promo.amount / 100).toLocaleString('id-ID')}`
        else
          result = parseInt(item.promo.amount).toLocaleString('id-ID')
      }
      return result
    },

    // check items count
    checkItems() {
      return (this.form.payment_categories_id.length == 0 && this.form.medicines_id.length == 0 && this.form.lab_procedures_id.length == 0)
    },

    // input additional @keyup
    inputAdditonKeyup(evt) {
      if ((evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 96 && evt.keyCode <= 105) || evt.keyCode == 8) {
        const discount = parseInt(this.form.discount.toString().replace(/\D/g, ''), 10)
        const surcharge = parseInt(this.form.surcharge.toString().replace(/\D/g, ''), 10)
        this.form.final_amount = this.form.total_amount - discount - this.couponAmount + surcharge
      } else {
        evt.preventDefault()
      }
    },

    // ---

    // modal patient @select
    async modalPatientOnSelect(value) {
      if (!value.has_sub_patient) {
        this.form.sub_patient_id = null
        this.form.sub_patient_name = ''
      }
      this.showCollapseSubPatient = value.has_sub_patient
    },

    // modal medicine @select
    async modalMedicineOnSelect(value, e) {
      if (value.stock <= 0) {
        Swal.fire({
          title: 'Pemberitahuan',
          text: 'Jumlah stok habis, harap lakukan isi ulang',
          icon: 'warning',
          showConfirmButton: false,
          timer: 1800,
          timerProgressBar: true
        })
        e.preventDefault()
        return
      }
      // let found = await PaymentModule.getMedicineById(value.id)
      // let isDuplicate = typeof this.form.medicines_id.find(x => x.id == found.id) === 'undefined' ? false : true
      let found = this.form.medicines_id.find(x => x.id == value.id)
      let isDuplicate = typeof found !== 'undefined'
      if (!isDuplicate) {
        // push to array names
        this.display.medicines_name_array.push({ id: value.id, name: value.name })
        // push to array
        this.form.medicines_id.push(value)
        this.form.total_amount += parseInt(value.const_price - value.promo_amount)
        this.form.final_amount += parseInt(value.const_price - value.promo_amount)
        this.form.total_promo_amount += parseInt(value.promo_amount)
      } else {
        this.form.total_amount -= parseInt((parseInt(found.const_price) - found.promo_amount) * found.quantity)
        this.form.final_amount -= parseInt((parseInt(found.const_price) - found.promo_amount) * found.quantity)
        this.form.total_promo_amount -= parseInt(value.promo_amount * found.quantity)
        // remove from array
        let index = this.form.medicines_id.indexOf(found)
        this.form.medicines_id.splice(index, 1)
        // remove from array names
        index = this.display.medicines_name_array.findIndex(x => x.id == value.id)
        this.display.medicines_name_array.splice(index, 1)
      }
    },

    // modal service @select
    async modalServiceOnSelect(value) {
      if (!value.custom_input) {
        let found = this.form.payment_categories_id.find(x => x.id == value.id)
        let isDuplicate = typeof found !== 'undefined'
        // add is_quote key
        value.is_quote = 0
        if (!isDuplicate) {
          // push to array names
          this.display.payment_categories_name_array.push({ id: value.id, name: value.name })
          // push to array
          this.form.payment_categories_id.push(JSON.parse(JSON.stringify(value)))
          this.form.total_amount += parseInt(value.const_price - value.promo_amount)
          this.form.final_amount += parseInt(value.const_price - value.promo_amount)
          this.form.total_promo_amount += parseInt(value.promo_amount)
        } else {
          this.form.total_amount -= parseInt((parseInt(found.const_price) - found.promo_amount) * found.quantity)
          this.form.final_amount -= parseInt((parseInt(found.const_price) - found.promo_amount) * found.quantity)
          this.form.total_promo_amount -= parseInt(value.promo_amount * found.quantity)
          // remove from array
          let index = this.form.payment_categories_id.indexOf(found)
          this.form.payment_categories_id.splice(index, 1)
          // remove from array names
          index = this.display.payment_categories_name_array.findIndex(x => x.id == value.id)
          this.display.payment_categories_name_array.splice(index, 1)
        }
      } else {
        // custom inputed service
        this.display.payment_categories_name_array.push({ id: value.id, name: value.label })
        this.form.payment_categories_id.push(value)
        this.form.total_amount += parseInt(value.const_price - value.promo_amount)
        this.form.final_amount += parseInt(value.const_price - value.promo_amount)
        this.form.total_promo_amount += parseInt(value.promo_amount)
      }
    },

    // modal service set service bundle item
    modalServiceSetServiceBundleItem(bundleItems, paymentCategory, edit = false) {
      bundleItems.forEach(value => {
        let find = this.form.payment_category_bundle_items_id.findIndex(x => x.payment_categories_id == value.payment_categories_id && x.parent_id == paymentCategory.id)
        if (find >= 0) {
          if (edit) {
            this.form.payment_category_bundle_items_id.splice(find, 1)
            this.form.payment_category_bundle_items_id.push(value)
          } else {
            this.form.payment_category_bundle_items_id.splice(find, 1)
          }
        } else {
          this.form.payment_category_bundle_items_id.push(value)
        }
      })
    },

    // ---

    // btn clear sub patient @click
    btnClearSubPatientOnClick() {
      if (!this.form.payment_status_id == 2) {
        this.form.sub_patient_id = null
        this.form.sub_patient_name = ''
      }
    },

    // btn clear selected service @click
    btnClearSelectedServiceOnClick() {
      if (!(this.form.payment_status_id == 2 || this.form.has_usage != 0)) {
        this.display.payment_categories_name_array = []
        this.resetSelectedService()
      }
    },

    // btn min service qty @click
    btnMinServiceQtyOnClick(data, index) {
      let items = this.form.payment_categories_id
      let found = items[index]
      let quantity = found.quantity
      this.form.total_amount -= parseInt(found.const_price) - found.promo_amount - found.discount_amount
      this.form.final_amount -= parseInt(found.const_price) - found.promo_amount - found.discount_amount
      this.form.total_promo_amount -= parseInt(found.promo_amount)
      if (quantity == 1) {
        quantity = 0
        items.splice(index, 1)
        index = this.display.payment_categories_name_array.findIndex(x => x.id == data.id)
        this.display.payment_categories_name_array.splice(index, 1)
      } else {
        found.quantity -= 1
        found.subtotal = (parseInt(found.const_price) - found.promo_amount) * found.quantity
      }
    },

    // btn add service qty @click
    btnAddServiceQtyOnClick(data, index) {
      let items = this.form.payment_categories_id
      let found = items[index]
      found.quantity += 1
      found.subtotal = (parseInt(found.const_price) - found.promo_amount) * found.quantity
      this.form.total_amount += parseInt(found.const_price) - found.promo_amount - found.discount_amount
      this.form.final_amount += parseInt(found.const_price) - found.promo_amount - found.discount_amount
      this.form.total_promo_amount += parseInt(found.promo_amount)
    },

    // checkbox service is deposit @change
    checkboxDepositOnChange(isDeposit, index) {
      let find = this.form.payment_categories_id[index]
      find.is_deposit = isDeposit == 1 ? 0 : 1
      let isAllDeposit = this.form.payment_categories_id.filter(x => x.is_deposit == 0)
      if (isAllDeposit.length == 0) {
        this.isAllServiceDeposit = true
      } else {
        this.isAllServiceDeposit = false
      }
    },

    // btn min medicine qty @click
    btnMinMedicineQtyOnClick(medicineId, index) {
      let items = this.form.medicines_id
      let found = items[index]
      let quantity = found.quantity
      this.form.total_amount -= parseInt(found.const_price) - found.promo_amount - found.discount_amount
      this.form.final_amount -= parseInt(found.const_price) - found.promo_amount - found.discount_amount
      this.form.total_promo_amount -= parseInt(found.promo_amount)
      if (quantity == 1) {
        quantity = 0
        items.splice(index, 1)
        index = this.display.medicines_name_array.findIndex(x => x.id == medicineId)
        this.display.medicines_name_array.splice(index, 1)
      } else {
        found.quantity -= 1
        found.subtotal = (parseInt(found.const_price) - found.promo_amount) * found.quantity
      }
    },

    // btn add medicine qty @click
    btnAddMedicineQtyOnClick(index) {
      let items = this.form.medicines_id
      let found = items[index]
      let quantity = found.quantity
      if (quantity >= found.stock) {
        Swal.fire({
          title: 'Pemberitahuan',
          text: 'Jumlah Melebihi Ketersediaan Stock!',
          icon: 'warning',
          showConfirmButton: false,
          timer: 1800,
          timerProgressBar: true
        })
        return
      }
      found.quantity += 1
      found.subtotal = (parseInt(found.const_price) - found.promo_amount) * found.quantity
      this.form.total_amount += parseInt(found.const_price) - found.promo_amount - found.discount_amount
      this.form.final_amount += parseInt(found.const_price) - found.promo_amount - found.discount_amount
      this.form.total_promo_amount += parseInt(found.promo_amount)
    },

    // btn clear selected medicine @click
    btnClearSelectedMedicineOnClick() {
      if (!(this.form.payment_status_id == 2 || this.form.has_usage != 0)) {
        this.mutated_medicines_id = []
        this.display.medicines_name_array = []
        this.resetSelectedMedicine()
      }
    },

    // lab procedures @select
    modalLabProcedureOnSelect(value) {
      let found = this.form.lab_procedures_id.find(x => x.id == value.id)
      let isDuplicate = typeof found !== 'undefined'
      if (!isDuplicate) {
        // push to array names
        this.display.lab_procedures_name_array.push({ id: value.id, name: value.name })
        // push to array
        this.form.lab_procedures_id.push(JSON.parse(JSON.stringify(value)))
        this.form.total_amount += parseInt(value.const_price - value.promo_amount)
        this.form.final_amount += parseInt(value.const_price - value.promo_amount)
        this.form.total_promo_amount += parseInt(value.promo_amount)
      } else {
        this.form.total_amount -= parseInt(found.price - found.promo_amount)
        this.form.final_amount -= parseInt(found.price - found.promo_amount)
        this.form.total_promo_amount -= parseInt(found.promo_amount)
        // remove from array
        let index = this.form.lab_procedures_id.indexOf(found)
        this.form.lab_procedures_id.splice(index, 1)
        // remove from array names
        index = this.display.lab_procedures_name_array.findIndex(x => x.id == value.id)
        this.display.lab_procedures_name_array.splice(index, 1)
      }
    },

    // lab procedures @deselect
    labProcedureOnDeselect(evt) {
      let lab = this.form.lab_procedures_id
      let found = lab.find(x => x.id == evt.id)
      if (found) {
        let index = lab.indexOf(found)
        this.form.total_amount -= parseInt(found.price - found.promo_amount)
        this.form.final_amount -= parseInt(found.price - found.promo_amount)
        this.form.total_promo_amount -= parseInt(found.promo_amount)
        lab.splice(index, 1)
        index = this.display.lab_procedures_name_array.findIndex(x => x.id == evt.id)
        this.display.lab_procedures_name_array.splice(index, 1)
      }
    },

    // btn clear selected lab procedure @click
    btnClearSelectedLabProcedureOnClick() {
      if (this.form.payment_status_id != 2) {
        this.display.lab_procedures_name_array = []
        this.resetSelectedLabProcedure()
      }
    },

    // btn custom medicine qty @click
    btnCustomItemQtyOnClick(item, type = '') {
      this.item.id = item.id
      this.item.name = item.label
      this.item.quantity = item.quantity
      this.item.default_price = item.price
      this.item.stock = item.stock
      this.item.type = type
      this.$bvModal.show('modal-item-quantity')
    },

    // btn item discount @click
    btnItemDiscountOnClick(index, id, name, default_price, discount_percentage, discount_amount, discount_type, type = '', quantity) {
      this.$bvModal.show('modal-item-discount')
      this.item.index = index
      this.item.discount_percentage = discount_percentage
      this.item.discount_amount = discount_amount
      this.item.id = id
      this.item.default_price = default_price
      this.item.name = name
      this.item.discount_type = discount_type
      this.item.type = type
      this.item.quantity = quantity
    },

    // btn quote price @click
    btnQuotePriceOnClick(type, item, index) {
      this.$bvModal.show('modal-quote-price')
      this.item.id = index
      this.item.name = item.name
      this.item.default_price = item.const_price
      this.item.quote_price = parseInt(item.price).toLocaleString('id-ID')
      this.item.quantity = item.quantity
      this.item.is_quote = item.is_quote
      this.item.type = type
    },

    // modal item quantity @submit
    modalItemQuantityOnSubmit(item) {
      let context = false
      if (item.type == 'payment-category') {
        context = this.form.payment_categories_id
      } else if (item.type == 'medicine') {
        context = this.form.medicines_id
      }
      if (context) {
        let found = context.find(x => x.id == item.id)
        if (found) {
          if (parseInt(item.quantity) > found.stock) {
            Swal.fire({
              title: 'Pemberitahuan',
              text: 'Jumlah Melebihi Ketersediaan Stock!',
              icon: 'warning',
              showConfirmButton: false,
              timer: 1800,
              timerProgressBar: true
            })
            return
          }
          // reset currency
          this.form.total_amount -= parseInt((found.price - found.promo_amount) * found.quantity)
          this.form.final_amount -= parseInt((found.price - found.promo_amount) * found.quantity)
          this.form.total_promo_amount -= parseInt(found.promo_amount * found.quantity)
          // apply new currency
          found.quantity = parseInt(item.quantity)
          found.subtotal = found.amount * found.quantity
          this.form.total_amount += parseInt((found.price - found.promo_amount) * found.quantity)
          this.form.final_amount += parseInt((found.price - found.promo_amount) * found.quantity)
          this.form.total_promo_amount += parseInt(found.promo_amount * found.quantity)
        }
      }
      this.$nextTick(() => {
        this.$bvModal.hide('modal-item-quantity')
      })
    },

    getDiscountAmount(item, quotedPrice) {
      if (item.discount_type == 1)
        return quotedPrice * (item.discount_percentage / 100)
      return item.discount_amount
    },

    // modal quote price @submit
    modalQuotePriceOnSubmit(item) {
      let filteredQuotePrice = item.quote_price != 0 ? parseInt(item.quote_price.replace(/\D/g, ''), 10) : 0
      let found = this.form.payment_categories_id[item.id]
      if (!found) {
        Swal.fire('Pemberitahuan', 'Gagal mengubah harga layanan', 'warning')
        return
      }
      // restore summary
      this.form.total_amount -= (parseInt(found.price) - (found.discount_amount * item.quantity) - found.promo_amount)
      this.form.final_amount -= (parseInt(found.price) - (found.discount_amount * item.quantity) - found.promo_amount)
      this.form.total_promo_amount -= parseInt(found.promo_amount * found.quantity)
      // set new value
      let discountAmount = this.getDiscountAmount(found, filteredQuotePrice)
      found.is_quote = 1
      found.promo_id = null
      found.promo_amount = 0
      found.price = filteredQuotePrice - discountAmount
      found.discount_amount = discountAmount
      // re calculate summary
      this.form.total_amount += parseInt((found.price - found.discount_amount) * item.quantity)
      this.form.final_amount += parseInt((found.price - found.discount_amount) * item.quantity)
      this.form.total_promo_amount += parseInt(found.promo_amount * found.quantity)
      Swal.fire('Berhasil', 'Berhasil mengubah harga layanan', 'success')
    },

    // modal item discount @submit
    modalItemDiscountOnSubmit() {
      let context = false
      if (this.item.type == 'payment-category') {
        context = this.form.payment_categories_id
      } else if (this.item.type == 'lab-procedure') {
        context = this.form.lab_procedures_id
      } else if (this.item.type == 'medicine') {
        context = this.form.medicines_id
      }
      let found = context[this.item.index]
      if (context && typeof found != 'undefined') {
        let constPrice = found.const_price
        let price = found.price
        let priceAfterDiscount
        let filteredDiscountAmount = this.item.discount_amount != 0 ? parseInt(this.item.discount_amount.toString().replace(/\D/g, ''), 10) : 0
        if (this.item.discount_type == 0) { // discount in rupiah                    
          if (filteredDiscountAmount > (constPrice - found.promo_amount)) {
            Swal.fire({
              title: 'Gagal',
              text: 'Diskon tidak boleh melebihi harga',
              icon: 'warning',
              allowEscapeKey: false,
              allowOutsideClick: false
            }).then(() => {
              this.$bvModal.show('modal-item-discount')
            })
            return
          }
          priceAfterDiscount = constPrice - filteredDiscountAmount
          found.discount_percentage = (filteredDiscountAmount / constPrice) * 100
          found.discount_type = 0
          found.discount_amount = filteredDiscountAmount
          found.price = priceAfterDiscount
          this.form.total_amount -= price * found.quantity
          this.form.total_amount += priceAfterDiscount * found.quantity
          this.form.final_amount -= price * found.quantity
          this.form.final_amount += priceAfterDiscount * found.quantity
          Swal.fire('Berhasil', 'Berhasil Menerapkan Diskon', 'success')
        } else if (this.item.discount_type == 1) {  // discount in percent
          if (this.item.discount_percentage > 100) {
            Swal.fire({
              title: 'Gagal',
              text: 'Diskon tidak boleh melebihi 100%',
              icon: 'warning',
              allowEscapeKey: false,
              allowOutsideClick: false
            }).then(() => {
              this.$bvModal.show('modal-item-discount')
            })
            return
          }
          constPrice -= found.promo_amount
          const oldDiscountAmount = found.discount_amount
          found.discount_amount = (constPrice * (this.item.discount_percentage / 100))
          priceAfterDiscount = constPrice - found.discount_amount
          found.discount_type = 1
          found.discount_percentage = this.item.discount_percentage
          found.price = priceAfterDiscount + found.promo_amount
          this.form.total_amount -= (constPrice - oldDiscountAmount) * found.quantity
          this.form.total_amount += priceAfterDiscount * found.quantity
          this.form.final_amount -= (constPrice - oldDiscountAmount) * found.quantity
          this.form.final_amount += priceAfterDiscount * found.quantity
          Swal.fire('Berhasil', 'Berhasil Menerapkan Diskon', 'success')
        }
      }
    },

    displayCoupon() {
      let couponInfo = ''
      if (this.coupon) {
        let couponAmount = `Rp ${this.couponAmount.toLocaleString('id-ID')}`
        couponInfo = `${this.coupon.name} ${this.coupon.is_percent == 1 ? `(${this.coupon.amount}%)` : ''} - ${couponAmount}`
      }
      return couponInfo
    },

    getCouponAmount(processed = false, value = null) {
      let coupon = value ? value : this.coupon
      let couponAmount = 0
      if (coupon) {
        if (coupon.is_percent == 1) {
          if (!processed)
            couponAmount = `${parseInt(coupon.amount)}%`
          else {
            couponAmount = parseInt((this.form.final_amount + this.form.coupon_amount) * (coupon.amount / 100))
            if (couponAmount > coupon.max_amount && coupon.max_amount > 0)
              couponAmount = coupon.max_amount
          }
        } else
          couponAmount = parseInt(coupon.amount)
      }
      return couponAmount
    },

    // modal coupon @select
    modalCouponOnSelect(value) {
      const finalAmountBeforeCoupon = this.form.final_amount + this.form.coupon_amount
      const newCouponAmount = this.getCouponAmount(true, value)
      // validate coupon amount
      if (finalAmountBeforeCoupon <= 0 || finalAmountBeforeCoupon < newCouponAmount) {
        Swal.fire('Pemberitahuan', 'Kupon tidak boleh melebihi nominal transaksi', 'warning')
        return
      }
      this.$bvModal.hide("modal-coupon")
      this.coupon = value
      this.form.coupon_id = value.id
      this.form.coupon_name = value.name
      this.form.coupon_is_percent = value.is_percent
      let couponPercentageAmount = 0
      if (value.is_percent == 1)
        couponPercentageAmount = value.amount
      this.form.coupon_percentage_amount = couponPercentageAmount
      // restore original final amount
      this.form.final_amount = finalAmountBeforeCoupon
      // set coupon amount
      this.form.coupon_amount = newCouponAmount
      // re calculate final amount
      this.form.final_amount = this.form.final_amount - newCouponAmount
    },

    // btn save @click
    async btnSaveOnClick() {
      if (this.checkItems()) {
        Swal.fire('Peringatan', 'Anda belum memilih item', 'warning')
        return
      }
      if (typeof this.$route.params.id != 'undefined') this.route += `/${this.$route.params.id}`
      let response = await module.submit(this.form, this.route)
      if (response.state == 'error') {
        this.error = validation.setValidationError(this.error, response.error.errors)
        return
      }
      Swal.fire(response.success.title, response.success.message, 'success')
      this.$router.push('/payment/list')
    },

    // btn checkout @click
    btnCheckoutOnClick() {
      if (typeof this.form.patient_id == 'undefined' || this.form.patient_id == '') {
        Swal.fire('Peringatan', 'Harap isi pasien', 'warning')
        return
      }
      if (this.checkItems()) {
        Swal.fire('Peringatan', 'Anda belum memilih item', 'warning')
        return
      }
      this.$emit('btnCheckoutOnClick')
    },

    resetSelectedService() {
      this.form.payment_categories_id.forEach(element => {
        this.form.total_amount -= parseInt((element.price - element.promo_amount) * element.quantity)
        this.form.final_amount -= parseInt((element.price - element.promo_amount) * element.quantity)
        this.form.total_promo_amount -= parseInt(element.promo_amount * element.quantity)
      })
      this.form.payment_categories_id = []
    },

    resetSelectedMedicine() {
      this.form.medicines_id.forEach(element => {
        this.form.total_amount -= parseInt((element.price - element.promo_amount) * element.quantity)
        this.form.final_amount -= parseInt((element.price - element.promo_amount) * element.quantity)
        this.form.total_promo_amount -= parseInt(element.promo_amount * element.quantity)
      })
      this.form.medicines_id = []
    },

    resetSelectedLabProcedure() {
      this.form.lab_procedures_id.forEach(element => {
        this.form.total_amount -= parseInt(element.price - element.promo_amount)
        this.form.final_amount -= parseInt(element.price - element.promo_amount)
        this.form.total_promo_amount -= parseInt(element.promo_amount * element.quantity)
      })
      this.form.lab_procedures_id = []
    },

  },

  watch: {
    'form.payment_categories_id': function (newVal) {
      let isAllDeposit = this.form.payment_categories_id.filter(x => x.is_deposit == 0)
      if (isAllDeposit.length == 0 && this.form.payment_categories_id.length != 0) {
        this.isAllServiceDeposit = true
      } else {
        this.isAllServiceDeposit = false
      }
    },
    'form.sub_patient_id': function (newVal) {
      if (newVal != null)
        this.showCollapseSubPatient = true
    },
    'form.coupon_id': function (newVal) {
      if (this.isEdit && this.form.coupon_id != null) {
        const couponAmount = this.form.coupon_percentage_amount > 0 ? this.form.coupon_percentage_amount : this.form.coupon_amount
        const objCoupon = {
          id: this.form.coupon_id,
          name: this.form.coupon_name,
          is_percent: this.form.coupon_percentage_amount > 0,
          amount: couponAmount
        }
        this.coupon = objCoupon
      }
    }
  },

  mounted() {
    if (this.currentUser.user_type_id == 1) {
      this.form.doctor_id = this.currentUser.user_id
    }
  }

}
</script>

<style scoped>
@media only screen and (max-width: 960px) {
  #scrollContainer {
    height: auto;
  }
}

@media only screen and (min-width: 961px) {
  #scrollContainer {
    overflow-y: auto;
    height: calc(100vh - 250px);
  }
}

.btn-quantity {
  min-width: 35px;
}
</style>