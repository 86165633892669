<template>
  <div>
    <div class="row align-items-center justify-content-end mt-n3">
      <div class="col-md-3 col-12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input type="text" v-model="filter.name" placeholder="Cari Berdasar Nama"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
    </div>
    <b-table ref="thisTable" striped hover class="mt-3" responsive :id="`${randomId}`" :items="items" :fields="fields">
      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.key === 'actions' ? '20%' : '' }" />
      </template>
      <template #cell(price)="data">
        <div><b>{{ parseInt(data.item.price - data.item.promo_amount).toLocaleString("id-ID") }}</b></div>
        <span v-if="data.item.promo">
          <b-button size="sm" class="p-1 mr-1 bg-light-danger text-danger font-weight-bolder">
            - {{ getPromoInfo(data.item) }}
          </b-button>
          <small><s>{{ parseInt(data.item.price).toLocaleString('id-ID') }}</s></small>
        </span>
      </template>
      <template #cell(isBundle)="data">
        <div class="d-flex">
          <b-badge v-if="data.item.isBundle == 0" pill variant="danger">
            <span class="px-1">Produk Layanan</span>
          </b-badge>
          <b-badge v-if="data.item.isBundle" pill variant="primary">
            <span class="px-1">Paket Produk</span>
          </b-badge>
        </div>
      </template>
      <template #cell(doctor_commission)="data">
        {{ data.item.doctor_commission }}
      </template>
      <template #cell(hospital_income)="data">
        {{ data.item.hospital_income }}
      </template>

      <!-- detail collapse -->
      <template #row-details="data">
        <!-- if bundle -->
        <div class="card" v-if="data.item.isBundle == true">
          <div class="card-body">
            <div class="row justify-content-end">
              <button class="btn btn-purple" type="button" @click="showModal(data.item.id, data.index)">
                + Tambah Layanan
              </button>
            </div>

            <ol>
              <li v-for="(val, index) in data.item.dataItems" :key="index">
                <div class="d-flex">
                  <span><b>{{ val.quantity }} x {{ val.name }}</b></span>
                  <i class="fas fa-chevron-up fa-md mt-1 ml-2" v-if="val.isActive == true"
                    @click="val.isActive = false"></i>
                  <i class="fas fa-chevron-down fa-md mt-1 ml-2" v-if="val.isActive == false"
                    @click="val.isActive = true"></i>
                </div>
                <div v-if="val.isActive == true">
                  <span class="d-block">Obat & Alkes :</span>
                  <ul style="list-style-type: circle" class="ml-4 d-block">
                    <li v-for="(value, key) in val.medicine" :key="key">
                      <span>{{ value.name }} ({{ value.quantity }})</span>
                    </li>
                  </ul>

                  <span class="d-block">Bahan Pendukung :</span>
                  <ul style="list-style-type: circle" class="ml-4 d-block">
                    <li v-for="(value, key) in val.inventory" :key="key">
                      <span>{{ value.name }} ({{ value.quantity }})</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <!-- end bundle -->

        <!-- if not bundle -->
        <div class="card" v-if="data.item.isBundle == false">
          <div class="card-body">
            <span class="d-block">Obat & Alkes :</span>
            <ul class="ml-4 d-block">
              <li v-for="(val, index) in data.item.dataItems.medicine" :key="index">
                <span>{{ val.name }} ({{ val.quantity }})</span>
              </li>
            </ul>

            <span class="d-block">Bahan Pendukung :</span>
            <ul style="list-style-type: circle" class="ml-4 d-block">
              <li v-for="(value, key) in data.item.dataItems.inventory" :key="key">
                <span>{{ value.name }} ({{ value.quantity }})</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- end not bundle -->
      </template>
      <!-- end detail collapse -->

      <template #cell(actions)="data">
        <div class="d-flex">
          <template v-if="purpose == 'master'">
            <b-button size="sm" class="mr-1 btn-primary" variant="warning" v-b-tooltip.hover title="Komisi Dokter" v-if="
              data.item.isBundle == 0 &&
              data.item.payment_category_type_id != 1
            " @click="
  $router.push({
    path:
      '/payment-categories/add-doctor-commision/' + data.item.id,
  })
"><i class="fas fa-user-md px-0"></i></b-button>
            <b-button size="sm" class="mr-1 btn-primary" variant="primary" v-b-tooltip.hover title="Info"
              @click="data.toggleDetails"><i class="fas fa-sitemap px-0"></i></b-button>
            <b-button size="sm" class="mr-1 btn-info" v-b-tooltip.hover title="Detail" @click="
              $router.push({
                path: '/payment-categories/detail/' + data.item.id,
              })
            "><i class="fas fa-eye px-0"></i></b-button>
            <b-button size="sm" class="mr-1 btn-success" v-b-tooltip.hover title="Edit" v-if="btn" @click="
              $router.push({
                path: '/payment-categories/edit/' + data.item.id,
              })
            "><i class="fas fa-edit px-0"></i></b-button>
            <b-button size="sm" class="btn-danger" v-b-tooltip.hover title="Hapus" v-if="btn"
              @click="btnDeleteOnClick(data.item.id)"><i class="fas fa-trash px-0"></i></b-button>
          </template>
          <template v-if="purpose == 'modal'">
            <div class="d-flex justify-content-center align-items-center">
              <b-form-checkbox size="lg" :checked="data.item.checked == 1"
                :id="`checkbox-pick-payment-category-${data.item.id}`"
                :name="`checkbox-pick-payment-category-${data.item.id}`" @change="
                  checkboxItemOnChange(
                    data.item,
                    data.index,
                    $event
                  )
                ">
              </b-form-checkbox>
              <!-- for bundle -->
              <template v-if="data.item.isBundle == 1">
                <!-- <b-button
                  v-if="data.item.checked"
                  variant="success"
                  size="sm"
                  class="mr-1"
                  @click="btnEditItemBundleOnClick({id: data.item.id, name: data.item.name, price: data.item.price, is_bundle: data.item.isBundle}, data.index, data.item.checked)"
                ><b>{{ setBtnItemBundleChosenCount(data.item) }}</b> Terpakai</b-button>
                <b-button
                  size="sm"
                  class="mr-1"
                  :variant="setBtnItemBundleVariant(data.item)"
                  @click="btnItemBundleOnClick({id: data.item.id, name: data.item.name, price: data.item.price, is_bundle: data.item.isBundle}, data.index, data.item.checked)"
                ><i :class="`fas fa-fw ${setBtnItemBundleIcon(data.item)}`"></i>{{ setBtnItemBundleText(data.item) }}</b-button> -->
                <b-button variant="info" size="sm" class="mx-1"
                  @click="btnSeeBundleDetailOnClick(data.item, data.index)"><i class="fas fa-fw fa-eye"></i> Lihat isi
                  paket</b-button>
              </template>
            </div>
          </template>
        </div>
      </template>
    </b-table>

    <b-pagination v-if="items.length != 0" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
      aria-controls="my-table" @page-click="pageOnClick" class="mt-4"></b-pagination>

    <ModalPaymentCategories :selectedItem="[]"></ModalPaymentCategories>

  </div>
</template>

<script>

import { debouncer, getPromoAmount } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import ModalPaymentCategories from "@/component/masters/payment-categories/ModalPaymentCategoriesAdd.vue";

export default {

  props: {
    purpose: String,
    type: String,
    selectedItem: Array,
    selectedBundle: Array,
  },

  components: {
    ModalPaymentCategories,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 10,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "isBundle",
          label: "Paket Produk",
          sortable: true,
        },
        // {
        //   key: "doctor_commission",
        //   label: "Komisi Dokter",
        //   sortable: true,
        // },
        // {
        //   key: "hospital_income",
        //   label: "Pendapatan Rumah Sakit",
        //   sortable: true,
        //   thStyle: { maxWidth: '90px' }
        // },
        { key: "actions", label: "Aksi", tdClass: "align-middle" },
      ],
      // Table Items
      items: [],
      randomId: Math.random(),
      // User Access
      btn: false,
      btnAddDoctorCommission: false,
    };
  },

  methods: {

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.lastCurrentPage = page;
      this.pagination();
    },

    checkboxItemOnChange(value, index, e) {
      this.$emit("checkboxItemOnChange", value, index, e);
    },

    btnItemBundleOnClick(value, index, checked) {
      this.$emit("btnItemBundleOnClick", value, index, checked);
    },

    btnEditItemBundleOnClick(value, index, checked) {
      this.$emit("btnEditItemBundleOnClick", value, index, checked);
    },

    btnSeeBundleDetailOnClick(value, index) {
      this.$emit("btnSeeBundleDetailOnClick", value, index);
    },

    getPromoInfo(item) {
      let result = ``
      if (item.promo) {
        if (item.promo.is_percent == 1)
          result = `${parseInt(item.promo.amount)}%, ${parseInt(item.price * item.promo.amount / 100).toLocaleString('id-ID')}`
        else
          result = parseInt(item.promo.amount).toLocaleString('id-ID')
      }
      return result
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      let response
      if (this.type == "promo") {
        response = await module.paginate(
          "payment-category-promos",
          `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
        )
      } else {
        response = await module.paginate(
          "payment-categories",
          `?page=${this.currentPage}&with_promo=true&page_size=${this.perPage}${filterParams}`
        )
      }
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        let row = response.data[a]
        // add number
        row.number = b + a
        // add others
        row.label = row.name
        row.const_price = row.price
        row.discount_percentage = 0
        row.discount_amount = 0
        row.discount_type = 0
        row.is_bundle = row.isBundle
        row.is_deposit = 0
        row.quantity = 1
        row.promo_amount = getPromoAmount(row.promo, row.const_price)
      }
      this.items = response.data
      // set checkbox checked
      this.setCheckedItem()
    },

    showModal(id, index) {
      this.$root.$emit("sendData", id, index);
      this.$bvModal.show("modal-payment-categories");
    },

    async updateDataOnTable() {
      this.pagination();
    },

    // async updateDataOnTable(data, id, index) {
    //   let a = 1
    //   console.log("====================", a);
    //   a++
    //   // get data medic
    //   data.forEach(async el => {
    //     let result = await module.get('payment-categories-medicine/' + el.id)
    //     let medic = {
    //       name: "",
    //       quantity: 0,
    //     }
    //     let medicData = []

    //     if (result.length != 0) {
    //       result.forEach(val => {
    //         medic.name = val.medicine_name
    //         medic.quantity = val.medicine_qty

    //         let found = { ...medic }
    //         medicData.push(found)

    //       });

    //       console.log(medicData);
    //     }

    //     let obj = {
    //       name: el.name,
    //       medicine: medicData,
    //       isActive: false
    //     }

    //     let dataPush = { ...obj }
    //     let unique = this.items[index].dataItems.findIndex(x => x.name === obj.name)
    //     if (unique < 0) {
    //       this.items[index].dataItems.push(dataPush)
    //     }
    //   });
    // },

    setCheckedItem() {
      this.items.forEach((value, index) => {
        let found = this.selectedItem.find((x) => x.id == value.id);
        if (typeof found === "undefined") {
          this.items[index].checked = false;
        } else {
          this.items[index].checked = true;
        }
      });
    },

    setBtnItemBundleText(data) {
      if (data.checked) return "Buang";
      else return "Pilih";
    },

    setBtnItemBundleIcon(data) {
      if (data.checked) return "fa-times";
      else return "fa-check";
    },

    setBtnItemBundleVariant(data) {
      if (data.checked) return "danger";
      else return "info";
    },

    setBtnItemBundleChosenCount(data) {
      let find = this.selectedBundle.filter(
        (x) => x.parent_id == data.id && x.checked == 1
      );
      return find.length;
    },

    filterByName() {
      debouncer(() => {
        this.currentPage = 1;
        if (this.filter.name == "") {
          this.currentPage = this.lastCurrentPage;
        }
        this.pagination();
      }, 500)
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("payment-categories/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6009") {
          this.btn = true;
        }
        if (access_right[a] == "6023") {
          this.btnAddDoctorCommission = true;
        }
      }
    },
  },

  watch: {
    selectedItem: function (newVal) {
      this.setCheckedItem();
    },
  },

  mounted() {
    // get table data
    this.pagination();
    // set button by permission
    this.setActiveButton();
    // emit listener
    this.$root.$on("sendSaveService", (data, id, index) => {
      this.updateDataOnTable(data, id, index);
    });
  },
};
</script>

<style></style>