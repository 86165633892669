<template>
  <div>

    <div class="row justify-content-end">
      <!-- filter by name -->
      <div class="col-md-3 col-12 mt-md-0 mt-n4">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input type="text" v-model="filter.name" placeholder="Cari nama sub pasien"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
    </div>

    <b-table striped hover responsive class="mt-3" :items="items" :fields="fields">
      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.key === 'actions' ? '10%' : '' }" />
      </template>
      <template #cell(born_date)="data">
        <span v-if="data.item.born_date != '0000-00-00 00:00:00' && data.item.born_date != null">
          {{ new Date(data.item.born_date).toLocaleDateString('id-ID', {
            weekday: 'long', year: 'numeric', month:
              'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'
          }).replaceAll('/', '-').replaceAll('.', ':') }}
        </span>
      </template>
      <template #cell(actions)="data">
        <b-button size="sm" class="mr-1 btn-info" @click="chooseSubPatient(data.item)">Pilih</b-button>
      </template>
    </b-table>

    <b-pagination v-if="items.length != 0" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
      @page-click="pageOnClick" class="mt-4">
    </b-pagination>

  </div>
</template>

<script>

import { debouncer } from "@/core/modules/Helper.js"
import module from '@/core/modules/CrudModule.js'

export default {

  props: {
    patientId: String
  },

  data() {
    return {
      // filter
      filter: {
        name: "",
      },
      // pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,
      // table fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama subpasien",
          sortable: true,
        },
        {
          key: "born_date",
          label: "Tgl lahir",
          sortable: true,
        },
        {
          key: "notes",
          label: "Catatan",
          sortable: false,
        },
        { key: "actions", label: "Aksi" },
      ],
      // items
      items: []
    }
  },

  methods: {

    filterByName() {
      debouncer(() => { this.pagination() }, 500)
    },

    chooseSubPatient(value) {
      this.$emit('chooseSubPatient', value)
    },

    async pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.lastCurrentPage = page
      this.pagination()
    },

    // get items
    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      let response = await module.paginate(
        `sub-patients/get-by-patient/${this.patientId}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      )
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data
    }

  },

  mounted() {
    this.pagination()
  }

}
</script>

<style lang="scss" scoped></style>