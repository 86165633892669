<template>
  <b-modal id="modal-subpatient" size="xl" :title="`Pilih Sub Pasien Dari ${patientName}`" hide-footer>
    <Table :patientId="patientId" @chooseSubPatient="chosenSubPatient" />
  </b-modal>
</template>

<script>

// components
import Table from '@/component/sub-patients/Table.vue'

export default {

  props: {
    patientId: String,
    patientName: String
  },

  components: {
    Table
  },

  methods: {

    chosenSubPatient(value) {      
      this.$emit('chosenSubPatient', value)
    }

  }

}
</script>

<style lang="scss" scoped></style>