<template>
  <b-modal
    id="modal-lab-procedure"
    size="xl"
    title="Pilih Laboratorium"
    hide-footer
    @show="modalOnShow"
    @hidden="modalOnHide">

    <div class="row justify-content-end mt-n3">
      <div class="col-md-3 col-12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Berdasar Nama"
            @keyup="filterByName">
          </b-form-input>
        </b-input-group>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      class="mt-3"
      style="white-space: nowrap"
      :items="items"
      :fields="fields">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '10%' : '' }" />
      </template>
      <template #cell(price)="data">
        <div class="font-weight-bolder">
          Rp {{ parseInt(data.item.price).toLocaleString('id-ID') }}
        </div>
      </template>
      <template #cell(doctor_commission)="data">
        <div>
          Rp {{ data.item.doctor_commission.toLocaleString('id-ID') }}
        </div>
      </template>
      <template #cell(hospital_income)="data">
        <div>
          Rp {{ data.item.hospital_income.toLocaleString('id-ID') }}
        </div>
      </template>
      <template #cell(actions)='data'>
        <b-form-checkbox
          :checked="setChecked(data.item.id)"
          :id="`checkbox-pick-lab-procedure-${data.item.id}`"
          :name="`checkbox-pick-lab-procedure-${data.item.id}`"
          @change="chooseItem(data.item)">
        </b-form-checkbox>
      </template>
    </b-table>

    <b-pagination
      class="mt-4"
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="my-table"
      @page-click="pageOnClick">
    </b-pagination>

  </b-modal>
</template>
  
<script>

import { debouncer, getPromoAmount } from "@/core/modules/Helper.js"
import module from '@/core/modules/CrudModule.js'

export default {

  props: {
    selectedItem: Array
  },

  data() {
    return {
      filter: {
        name: ''
      },
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "lab_category_name",
          label: "Kategori Lab",
          sortable: true,
        },
        {
          key: "doctor_commission",
          label: "Komisi Dokter",
          sortable: true,
        },
        {
          key: "hospital_income",
          label: "Pendapatan Rumah Sakit",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      items: []
    }
  },

  methods: {

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      let response = await module.paginate('lab-procedures', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        let row = response.data[a]
        row.number = b + a
        row.label = row.name
        row.const_price = row.price
        row.discount_percentage = 0
        row.discount_amount = 0
        row.discount_type = 0
        row.is_bundle = row.isBundle
        row.is_deposit = 0
        row.quantity = 1
        row.promo_amount = getPromoAmount(row.promo, row.const_price)
      }
      this.items = response.data
    },

    modalOnHide(e) {
      this.filter.name = ''
      this.currentPage = 1
      this.totalRows = 1
      this.items = []
    },

    modalOnShow(e) {
      this.pagination()
    },

    filterByName(e) {
      debounce(() => { this.pagination() }, 500)
    },

    chooseItem(value) {
      this.$emit("chooseItem", value)
    },

    setChecked(id) {
      return this.selectedItem.findIndex(x => x.id == id) >= 0
    }

  }

}
</script>